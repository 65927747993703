<template>
  <v-container>
    <v-row dense>
      <v-col>
        <v-text-field label="Vyhladat"></v-text-field>
      </v-col>
    </v-row>

    <v-row
      v-if="admin"
      dense
    >
      <v-col align="right">
        <v-btn
          small
          color="success"
          @click="createPollCategory()"
        >Nova kategoria</v-btn>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-expansion-panels
          multiple
          hover
        >

          <v-expansion-panel
            v-for="category in categories"
            v-bind:key="category.id"
          >
            <v-expansion-panel-header><strong>{{ category.order }}. {{category.name}} (ID {{category.id}})<v-icon v-show="!category.active">mdi-eye-outline</v-icon><v-icon v-show="category.private">mdi-file-hidden</v-icon></strong><v-spacer></v-spacer> KATEGORIA</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col align="right">
                  <v-btn
                    small
                    color="success"
                    @click="createPoll(category.id)"
                  >Nova Anketa</v-btn>
                </v-col>
              </v-row>

              <poll-category-detail
                :admin="admin"
                :itemID="category.id"
                @sync-child="getCategories()"
              ></poll-category-detail>
              <v-expansion-panels
                multiple
                hover
              >
                <v-expansion-panel
                  v-for="poll in category.polls"
                  v-bind:key="poll.id"
                >
                  <v-expansion-panel-header><strong>{{ poll.order }}. {{poll.name}} (ID {{poll.id}})<v-icon v-show="!poll.active">mdi-eye-outline</v-icon><v-icon v-show="poll.private">mdi-file-hidden</v-icon></strong> <v-spacer></v-spacer> ANKETA</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col align="right">
                        <v-btn
                          small
                          color="success"
                          @click="createPollQuestion(poll.id)"
                        >Nova moznost</v-btn>
                      </v-col>
                    </v-row>

                    <poll-detail
                      :admin="admin"
                      :itemID="poll.id"
                      @sync-child="getCategories()"
                    ></poll-detail>

                    <v-expansion-panels
                      multiple
                      hover
                    >
                      <v-expansion-panel
                        v-for="question in poll.questions"
                        v-bind:key="question.id"
                      >
                        <v-expansion-panel-header><strong>{{ question.order }}. {{ question.first_field }} {{ question.second_field }} <v-icon v-show="!question.active">mdi-eye-outline</v-icon></strong> <v-spacer></v-spacer> OTAZKA</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row dense>
                            <v-col align="right">
                              <v-btn
                                small
                                color="success"
                                @click="createPollSubQuestion(question.id)"
                              >Nova suvisiaca otazka</v-btn>
                            </v-col>
                          </v-row>

                          <poll-question-detail
                            :admin="admin"
                            :itemID="question.id"
                            @sync-child="getCategories()"
                          ></poll-question-detail>

                          <v-expansion-panels
                            multiple
                            hover
                          >
                            <v-expansion-panel
                              v-for="subQuestion in question.sub_questions"
                              v-bind:key="subQuestion.id"
                            >
                              <v-expansion-panel-header><strong>{{ subQuestion.order }}. {{ subQuestion.first_field }} {{ subQuestion.second_field }} <v-icon v-show="!subQuestion.active">mdi-eye-outline</v-icon></strong> <v-spacer></v-spacer> PODOTAZKA</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <poll-question-detail
                                  :itemID="subQuestion.id"
                                  @sync-child="getCategories()"
                                ></poll-question-detail>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>

                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import { APIService } from "@/http/APIService.js";
import PollCategoryDetail from "./PollCategoryDetail.vue";
import PollDetail from "./PollDetail.vue";
import PollQuestionDetail from "./PollQuestionDetail.vue";

// import { mapGetters } from "vuex";

const apiService = new APIService();

export default {

  components: {
    PollCategoryDetail,
    PollDetail,
    PollQuestionDetail
  },

  props: {
    admin: { type: Boolean, required: false, default: true },
  },

  data() {
    return {
      categories: []
    }
  },

  mounted() {
    this.getCategories();
  },

  methods: {
    getCategories() {
      if (this.admin)
        apiService.getGeneralPollCategoriesAdmin("", "", 0, 0, []).then(response => {
          this.categories = response.results;
        });
      else {
        apiService.getGeneralPollCategoriesModerator("", "", 0, 0, []).then(response => {
          this.categories = response.results;
        });
      }
    },
    createPollCategory() {
      apiService.createGeneralPollCategoryAdmin({}).then(() => {
        this.getCategories();
      });
    },
    createPoll(id) {
      apiService.createGeneralPollAdmin({ "poll_category": id }).then(() => {
        this.getCategories();
      });
    },
    createPollQuestion(id) {
      apiService.createGeneralPollQuestionAdmin({ "poll": id }).then(() => {
        this.getCategories();
      });
    },

    createPollSubQuestion(id) {
      apiService.createGeneralPollQuestionAdmin({ "parent_question": id }).then(() => {
        this.getCategories();
      });
    },

  }
}
</script>