<template>
    <v-card>
      <v-card-title>
        Privatna skupina
      </v-card-title>
      <v-card-text>
        <v-row
          dense
          v-for="user in users"
          v-bind:key="user.id"
        >
          <v-col cols="8">
            <strong>{{ user.id }}</strong>: {{ user.username }} {{ user.first_name }} {{ user.last_name }}
          </v-col>
          <v-col cols="4">
            <v-btn
              small
              color="error"
              @click="remove(user)"
            >Odstranit clena</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
  
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="new_admin_id"
              label="ID noveho clena"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn @click="add(new_admin_id)">
                Pridat clena
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
  
    </v-card>
  </template>
      <script>
  import { APIService } from "@/http/APIService.js";
  
  const apiService = new APIService();
  
  export default {
    props: {
      users: { type: Array, required: true },
      addPrivateFunc: { type: Function, required: true },
      removePrivateFunc: { type: Function, required: true },
    },
  
    data() {
      return {
        new_admin_id: null,
      }
    },
  
    mounted() {
    },
  
    methods: {
      remove(user) {
        confirm("Naozaj zmazat?") && this.removePrivateFunc(user.id).then(() => {
          this.$emit("sync-child");
        });
      },
  
      add(user_id) {
        confirm("Naozaj pridat?") && this.addPrivateFunc(user_id).then(() => {
          this.$emit("sync-child");
        });
      },
    }
  }
      </script>