<template>
  <v-card>
    <v-card-title>
      Administratori
    </v-card-title>
    <v-card-text>
      <v-row
        dense
        v-for="user in users"
        v-bind:key="user.id"
      >
        <v-col cols="8">
          <strong>{{ user.id }}</strong>: {{ user.username }} {{ user.first_name }} {{ user.last_name }} {{ user.email }} {{ user.phone_number }}
        </v-col>
        <v-col cols="4">
          <v-btn
            small
            color="error"
            @click="remove(user)"
          >Odstranit administratora</v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            v-model="new_admin_id"
            label="ID noveho administratora"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn @click="add(new_admin_id)">
            Pridat noveho administratora
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

  </v-card>
</template>
    <script>
import { APIService } from "@/http/APIService.js";

const apiService = new APIService();

export default {
  props: {
    users: { type: Array, required: true },
    addAdminFunc: { type: Function, required: true },
    removeAdminFunc: { type: Function, required: true },
  },

  data() {
    return {
      new_admin_id: null,
    }
  },

  mounted() {
  },

  methods: {
    remove(user) {
      confirm("Naozaj zmazat?") && this.removeAdminFunc(user.id).then(() => {
        this.$emit("sync-child");
      });
    },

    add(user_id) {
      confirm("Naozaj pridat?") && this.addAdminFunc(user_id).then(() => {
        this.$emit("sync-child");
      });
    },
  }
}
    </script>